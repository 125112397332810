import { Fragment } from "react";
import "./styles.scss";
import Skeleton from 'react-loading-skeleton';
import ReviewCardSkeleton from "../ReviewCardSkeleton";

const PropertyDetailSkeleton = () => {

    return (
        <Fragment>
            <div id="details">
                <div className="container">
                    <div className="details">
                        <div className="details-header-skeleton">
                            <div className="details-location">
                                <p className="location-short"><Skeleton height={20} /></p>
                                <div className="location-long">
                                    <Skeleton width={"70%"} />
                                </div>

                                <div className="last-review-date">
                                    <Skeleton width={'50%'} />
                                </div>
                            </div>
                        </div>

                        <div className="post-review-button-section-skeleton">
                            <div className="text-section">
                                <Skeleton height={30}/>
                            </div>
                            <div className="button-section">
                                <Skeleton height={20}/>
                            </div>
                        </div>

                        <div className="accordion-section d-block d-xl-none">
                            <Skeleton height={25} width={'100%'}/>
                            <Skeleton height={23} width={'80%'}/>
                            <Skeleton height={20} width={'50%'}/>
                        </div>

                        <div className="tab-section d-none d-xl-block">
                            <ReviewCardSkeleton />
                            <ReviewCardSkeleton />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default PropertyDetailSkeleton;