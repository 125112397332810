import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import Header from "Components/Header";
import { useQuery } from "@tanstack/react-query";
import { fetchProfile } from "Api/Profile";
import { setProfile } from "Pages/Search/slice";
import CustomSpinner from "Components/Common/Spinner";
import { useDispatch } from 'react-redux';
import "./styles.scss";

import { ToastNotification } from "Components/Common/Toast";

const ProtectedRoute = (props: any) => {
    const { children } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        Auth.currentAuthenticatedUser().then(() => {
            setIsLoading(false);
            setIsAuthenticated(true);
        }).catch(() => {
            setIsAuthenticated(false);
            setIsLoading(false);
            navigate("/login");
        });
    }, [navigate]);

    const { data } = useQuery({
        queryKey: ["profile"],
        queryFn: () => fetchProfile(),
        select: ({ data }) => data
    });

    useEffect(() => {
        if (data && data.details) {
            dispatch(setProfile(data.details));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (!isAuthenticated && !isLoading) {
        return <div>You must be logged in to access this page.</div>;
    }

    return <Fragment>
        {isLoading ? <div className="spinner">
            <CustomSpinner color="#000" />
        </div> : <Fragment>
            <Header />
            {children}
            <ToastNotification />
        </Fragment>}
    </Fragment>
};

export default ProtectedRoute;
