import { Auth } from 'aws-amplify';
import "./styles.scss";
import PrimaryButton from 'Components/Common/Buttons/PrimaryButton';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../../../App/store';
import { passwordSchema } from "Validations/settings.schema";
import { toast } from 'react-toastify';
import PasswordInput from "Components/Common/InputFields/PasswordInput";
import Swal from 'sweetalert2';
import { deleteProfile } from 'Api/Profile';
import { useNavigate } from "react-router-dom";
import { logout } from "Pages/Search/slice";
import { somethingWentWrong } from 'Messages/errorMessages';

const Security = () => {

    const { profile } = useSelector((state: RootState) => state.auth);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isPasswordUpdate, setIsPasswordUpdate] = useState<boolean>(false);

    const updatePasswordForm = useFormik({
        initialValues: {
            password: '',
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: passwordSchema,
        onSubmit: async (values) => {
            const user = await Auth.currentAuthenticatedUser();
            setIsPasswordUpdate(true);

            try {
                await Auth.changePassword(
                    user,
                    values.password,
                    values.newPassword
                );

                toast("Password updated", {
                    type: toast.TYPE.SUCCESS,
                    containerId: "toast-message",
                    autoClose: 3000
                });
            } catch (error: any) {
                if (error.code === "NotAuthorizedException") {
                    toast("Incorrect username or password", {
                        type: toast.TYPE.ERROR,
                        containerId: "toast-message",
                        autoClose: 3000
                    });
                }
            }

            setIsPasswordUpdate(false);
        },
    });

    useEffect(() => {
        return () => {
            updatePasswordForm.resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDeleteConfirmation = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "This will delete all information & data relating with your account and this is permanent which can't be reversed",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteAccount();
            }
        })
    };

    const deleteAccount = async () => {

        const data = await deleteProfile();

        if (data && data.data && data.data.status === true) {
            Swal.fire({
                title: 'Account deletion initiated',
                html: 'You will be notified by the email address once your account and related data is completely deleted',
                timer: 5000,
                timerProgressBar: true,
                allowOutsideClick: false,
                showConfirmButton: false

            }).then(async () => {
                await Auth.signOut();

                setTimeout(() => {
                    dispatch(logout());
                    navigate("/login");
                }, 1000);

            });
        } else {
            toast(somethingWentWrong.message, {
                type: toast.TYPE.ERROR,
                containerId: "toast-message",
                autoClose: 3000
            });
        }
    };

    return (
        <div className='security-section'>
            <div className='section-header'>
                <p className='section-title'>Security Settings</p>
                <p className='section-subtitle'>
                    Update your account password or delete your account
                </p>
            </div>

            {profile && profile["provider"] === "email" &&
                <form onSubmit={updatePasswordForm.handleSubmit}>
                    <div className='content-section'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-3">
                                        <PasswordInput
                                            id="password"
                                            name="password"
                                            placeholder="Enter current password"
                                            showLabel={true}
                                            labelText="Current Password"
                                            errors={updatePasswordForm.errors}
                                            touched={updatePasswordForm.touched}
                                            testId="password"
                                            value={updatePasswordForm.values.password}
                                            handleChange={(e) => updatePasswordForm.handleChange(e)}
                                        />
                                    </Form.Group>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-3">
                                        <PasswordInput
                                            id="newPassword"
                                            name="newPassword"
                                            placeholder="Enter new password"
                                            showLabel={true}
                                            labelText="New Password"
                                            errors={updatePasswordForm.errors}
                                            touched={updatePasswordForm.touched}
                                            value={updatePasswordForm.values.newPassword}
                                            testId="newPassword"
                                            handleChange={(e) => updatePasswordForm.handleChange(e)}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-3">
                                        <PasswordInput
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            placeholder="Confirm password"
                                            showLabel={true}
                                            labelText="Confirm Password"
                                            errors={updatePasswordForm.errors}
                                            touched={updatePasswordForm.touched}
                                            value={updatePasswordForm.values.confirmPassword}
                                            testId="confirmPassword"
                                            handleChange={(e) => updatePasswordForm.handleChange(e)}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <PrimaryButton
                                        type="submit"
                                        buttonText="Update Password"
                                        variant="primary"
                                        isLoading={isPasswordUpdate}
                                        disabled={!updatePasswordForm.isValid}
                                        customClassNames="update-button"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>}

            <div className='delete-section'>
                <p className='section-title'>Delete Account</p>
                <p className='section-subtitle'>Are you sure you want to delete your account? This will remove all the activities, reviews you have posted and everything.</p>

                <PrimaryButton
                    type="submit"
                    buttonText="Delete Account"
                    variant="primary"
                    isLoading={false}
                    disabled={false}
                    buttonClick={getDeleteConfirmation}
                    customClassNames="delete-button"
                />
            </div>
        </div>
    );
};

export default Security;