export const somethingWentWrong = {
    code: "somethingWentWrong",
    message: "Something went wrong. Please try again later."
};

export const codeVerification = {
    code: "codeVerification",
    message: "Please check the email address for the verification code."
};

export const emailExists = {
    code: "emailExists",
    message: "An account with the given email already exists. Please login to continue."
};

export const codeMismatch = {
    code: "codeMismatch",
    message: "Invalid verification code. Please provide a valid code."
};

export const loginNotAuthorized = {
    code: "loginNotAuthorized",
    message: "Invalid email or password"
};

export const logoutFailed = {
    code: "logoutFailed",
    message: "Failed to logout. Please try again."
};

export const suggestCategorySuccess = {
    code: "suggestCategorySuccess",
    message: "Thank you for suggesting a new category"
};

export const suggestCategoryFailure = {
    code: "suggestCategoryFailure",
    message: "Failed to record your suggestion. Please try again."
};