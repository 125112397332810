import "./styles.scss";
import AboutImage from "Assets/images/about.jpg";

const About = () => {
    return (
        <section className="about">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="title">About Us</div>
                        <div className="content-section">
                            <div className="row">
                                <div className="col-lg-5">
                                    <p className="content">
                                        RateMyGhar - Your trusted source for information on homes in the UK. The UK is facing a rent crunch, with rents rising faster than incomes.
                                        This can make it difficult for tenants to find affordable housing. That&apos;s why we created RateMyGhar - a one-stop shop for tenants
                                        and home buyers to find comprehensive information about the homes they&apos;re considering. We provide a comprehensive rating for
                                        each home based on a variety of factors, including location, condition, and reputation. We also provide detailed information
                                        about each home, such as square footage, number of bedrooms and bathrooms, amenities, and photos.
                                    </p>
                                </div>
                                <div className="col-lg-7">
                                    <div className="image-box">
                                        <img src={AboutImage} className="about-image" height={400} width={750} alt="About" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <p className="content">
                                        All the info you find
                                        on RateMyGhar is submitted by people like us, who lived on the property before or know about it. We believe that everyone
                                        deserves to live in a safe, comfortable, and affordable home. RateMyGhar helps you find such a home.
                                    </p>

                                    <p className="content">
                                        At RateMyGhar, we&apos;re committed to helping our users find the perfect home. If you have any questions or need assistance using our website,
                                        please don&apos;t hesitate to contact us. We&apos;re always happy to help.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;