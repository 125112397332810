import { Outlet } from "react-router-dom";
import { Fragment } from "react";
import { ToastNotification } from "Components/Common/Toast";
import Header from "Components/Header";
import Footer from "Components/Footer";

const PublicRoute = () => {
    return <Fragment>
        <Header />
        <Outlet />
        <Footer />
        <ToastNotification />
    </Fragment>
};

export default PublicRoute;