import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { PostReviewModalProps } from "./types";
import "./styles.scss";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ReviewFormTabContent from "../ReviewFormTabContent";
import { tabData } from "../../data";
import CategoryForm from "../CategoryForm";
import Accordion from 'react-bootstrap/Accordion';
import Swal from 'sweetalert2';

const PostReviewModal = (props: PostReviewModalProps) => {

    const { sefUrl, show, closeButton, propertyDetails, username } = props;

    const [activeTab, setActiveTab] = useState<string>("general");
    const [promptSave, setPromptSave] = useState<boolean>(false);

    const onBackButtonClick = (activeTabIndex: number) => {
        const nextTab = tabData[activeTabIndex - 1];
        setActiveTab(nextTab.eventKey);
    };

    const onNextButtonClick = (activeTabIndex: number) => {
        const nextTab = tabData[activeTabIndex + 1];
        if (tabData.length !== activeTabIndex + 1) {
            setActiveTab(nextTab.eventKey);
        }
    };

    const onModalClose = () => {
        if (promptSave) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You changes won't be saved",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    closeButton("close");
                    setActiveTab("general");
                }
            })
        } else {
            closeButton("close");
            setActiveTab("general");
        }
    };

    const promptOnClose = (action: boolean) => {
        setPromptSave(action);
    };

    return (
        <Modal
            show={show}
            size="xl"
            dialogClassName="modal-90w"
            aria-labelledby="post-review-modal-title"
        >
            <Modal.Header closeButton onHide={onModalClose}>
                <Modal.Title id="post-review-modal-title">
                    Manage Review
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="post-review-modal">
                    <div className="post-review-header-section">
                        <div className="left-section">
                            <div className="card-section">
                                <div className="card-name">{propertyDetails && propertyDetails.line_1}</div>
                                <div className="card-location">
                                    <span>{propertyDetails && propertyDetails.town_or_city}, </span>
                                    {propertyDetails && propertyDetails.county && <span>{propertyDetails.county}, </span>}
                                    <span>{propertyDetails && propertyDetails.country}</span>
                                </div>
                            </div>
                        </div>

                        <div className="add-category-accordion">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        Don't see a category that you wish to post a review? Click here to suggest adding a new category to the list
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <CategoryForm />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>

                    <div className="post-review-content-accordion-section d-block d-xl-none">
                        <Accordion defaultActiveKey="0">
                            {tabData && tabData.length > 0 && tabData.map((item, idx) => {
                                return <Accordion.Item
                                    key={`accordion-${item.eventKey}`}
                                    eventKey={item.eventKey}>
                                    <Accordion.Header>{item.title}</Accordion.Header>
                                    <Accordion.Body>
                                        <ReviewFormTabContent
                                            tabData={tabData}
                                            sefUrl={sefUrl}
                                            username={username}
                                            promptOnClose={promptOnClose}
                                            reviews={propertyDetails && propertyDetails.reviews!}
                                            activeTab={item.eventKey}
                                            showBackButton={false}
                                            showNextButton={true}
                                            onBackButtonClick={() => onBackButtonClick(idx)}
                                            onNextButtonClick={() => onNextButtonClick(idx)}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                            })}
                        </Accordion>
                    </div>

                    <div className="post-review-content-tab-section d-none d-xl-block">
                        <Tabs
                            defaultActiveKey="general"
                            id="post-review-tab"
                            className="mb-3"
                            activeKey={activeTab}
                            onSelect={(activeKey) => {
                                setActiveTab(activeKey!);
                            }}>
                            {tabData && tabData.length > 0 && tabData.map((item, idx) => {
                                return <Tab
                                    key={item.eventKey}
                                    eventKey={item.eventKey}
                                    title={item.title}>
                                    <ReviewFormTabContent
                                        tabData={tabData}
                                        sefUrl={sefUrl}
                                        username={username}
                                        promptOnClose={promptOnClose}
                                        reviews={propertyDetails && propertyDetails.reviews!}
                                        activeTab={item.eventKey}
                                        showBackButton={false}
                                        // showBackButton={item.eventKey !== "general"}
                                        showNextButton={true}
                                        onBackButtonClick={() => onBackButtonClick(idx)}
                                        onNextButtonClick={() => onNextButtonClick(idx)}
                                    />
                                </Tab>
                            })}
                        </Tabs>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PostReviewModal;