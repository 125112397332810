import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import "./styles.scss";
import HomeImage from "Assets/images/home.jpg";
import PostboxImage from "Assets/images/postbox.jpg";
import ReviewImage from "Assets/images/reviews.jpg";

const Home = () => {
    const navigate = useNavigate();

    const navigateToSearch = () => {
        navigate("/search");
    };

    return (
        <Fragment>
            <section id="home" className="home">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <h1 className="home-title">Find your perfect home in the UK.</h1>
                            <p className="home-subtitle">
                                Discover a wide range of properties for sale and rent across the UK. Search by postcode, browse reviews, and explore neighborhood insights.
                            </p>

                            <PrimaryButton
                                customClassNames="home-search-button"
                                type="button"
                                buttonClick={navigateToSearch}
                                buttonText="Search Properties"
                            />
                        </div>

                        <div className="col-12 col-lg-5">
                            <img src={HomeImage} alt="home"
                                className="home-image"
                            //  width={500} height={250} 
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section id="features" className="features">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-9">
                            <div className="feature-header-section">
                                <h1 className="feature-header-title">Discover the Features That Make Your Home Search a Breeze</h1>
                                <p className="feature-header-content">Experience the convenience of postcode search, delve into neighborhood insights, and gain valuable perspectives through real resident reviews. </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="feature-item">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-7">
                                <div className="feature-content">
                                    <h1 className="feature-title">Find Your Property by Postcode</h1>
                                    <p className="feature-subtitle">
                                        Narrow your search and find properties in your desired location with just a few clicks. Enter your postcode and discover a wide range of homes to suit your needs and preferences.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5">
                                <div className="image-box">
                                    <img src={PostboxImage} alt="postbox" className="postbox-image"
                                    // width={500} height={300} 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="feature-item">
                    <div className="container">
                        <div className="row d-sm-block d-lg-none">
                            <div className="col-12 col-lg-5">
                                <div className="feature-content-mobile">
                                    <h1 className="feature-title">Read Real Reviews and Make Informed Decisions</h1>
                                    <p className="feature-subtitle">
                                        Gain valuable insights from real residents and make informed decisions about your next home. Explore reviews categorized by various aspects, such as location, amenities, and overall experience.
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 col-lg-7">
                                <div className="image-box">
                                    <img src={ReviewImage} alt="review" className="review-image"
                                    //  width={500} height={300} 
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row d-none d-lg-flex">
                            <div className="col-12 col-lg-5">
                                <div className="image-box">
                                    <img src={ReviewImage} alt="review" className="review-image"
                                    //  width={500} height={300} 
                                    />
                                </div>
                            </div>

                            <div className="col-12 col-lg-7">
                                <div className="feature-content-right">
                                    <h1 className="feature-title">Read Real Reviews and Make Informed Decisions</h1>
                                    <p className="feature-subtitle">
                                        Gain valuable insights from real residents and make informed decisions about your next home. Explore reviews categorized by various aspects, such as location, amenities, and overall experience.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default Home;