import { Fragment } from "react";
import { timestampToDate } from "Helpers";
import ReviewCard from "../ReviewCard";
import { tabData } from "Pages/Details/data";
import { ReviewSectionProps } from "./types";

const ReviewSection = (props: ReviewSectionProps) => {

    const { reviews, isLoggedIn, maskLimit, activeTab } = props;

    return (
        <Fragment>
            {reviews && reviews.length > 0 &&
                reviews.slice(0, isLoggedIn ? reviews.length : maskLimit)
                    .map((review: any, idx: number) => {
                        let name = '';
                        let imageLetter = '';

                        if (review.users && review.users.length > 0) {
                            name = review.users[0].name;
                            imageLetter = name.charAt(0).toUpperCase();
                        }

                        return <ReviewCard
                            maskContent={!isLoggedIn && idx + 1 === maskLimit}
                            isLoggedIn={isLoggedIn}
                            key={`${review.category}-${idx}`}
                            image={imageLetter}
                            name={name}
                            rating={Number(review.score)}
                            comment={review.comment}
                            reviewImages={review.images}
                            postedDate={timestampToDate(review.created_at, "dateTime")!}
                        />
                    })}
            {reviews && reviews.length === 0 &&
                <p className="no-review-text">No
                    {activeTab === tabData[tabData.length - 1].eventKey ?
                        ` ${activeTab.replace("-", " ").toLowerCase()} `
                        : ` ${activeTab.toLowerCase()} `} reviews</p>}
        </Fragment>
    );
};

export default ReviewSection;