import React from "react";
import Button from 'react-bootstrap/Button';
import { PropType } from "./types";
import "./styles.scss";
import CustomSpinner from "../../Spinner";

const IconButton = (props: PropType) => {

    const { type, buttonText, variant, customClassNames,
        image, imageAltText, buttonClick, isLoading } = props;

    return (
        <Button
            type={type}
            className={customClassNames}
            onClick={(e) => {
                buttonClick && buttonClick!(e)
            }}
            variant={variant}>
            <img src={image} alt={imageAltText} />
            <span>
                {isLoading ? <CustomSpinner /> : buttonText}
            </span>
        </Button>
    );
};

export default IconButton;