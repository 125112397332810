// Validate name
export const validateName = (name: string) => {
    if (name && (name === "" || name === null)) {
        return false;
    } else {
        let regex = /^[a-zA-Z. ]+$/;
        return regex.test(name);
    }
};

// Validate email
export const validateEmail = (email: string) => {
    let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
};