import { useState } from "react";
import { useFormik } from 'formik';
import { Link } from "react-router-dom";
import { LoginForm, VerifyAccountForm } from "./types";
import { Auth } from 'aws-amplify';
import EmailInput from "Components/Common/InputFields/EmailInput";
import PasswordInput from "Components/Common/InputFields/PasswordInput";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import IconButton from "Components/Common/Buttons/IconButton";
import GoogleLogo from "Assets/icons/google.svg";
import "./styles.scss";
import CustomAlert from "Components/Common/Alert";
import {
    loginNotAuthorized, somethingWentWrong, codeMismatch,
    codeVerification
} from "Messages/errorMessages";
import { useNavigate } from "react-router-dom";
import TextInput from "Components/Common/InputFields/TextInput";
import { verificationSchema, loginSchema } from "Validations/sign-up.schema";
import { userVerified } from "Messages/successMessage";
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { login } from "Pages/Search/slice";

const Login = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [username, setUsername] = useState<string | null>(null);
    const [showVerificationForm, setShowVerificationForm] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);
    const [messageVariant, setMessageVariant] = useState<string | null>(null);
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const [isVerifying, setIsVerifying] = useState<boolean>(false);

    const loginForm = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: loginSchema,
        onSubmit: values => {
            setIsLoggingIn(true);
            authenticate(values);
        },
    });

    const verifyForm = useFormik({
        initialValues: {
            code: '',
        },
        validationSchema: verificationSchema,
        onSubmit: values => {
            setIsVerifying(true);
            verifyUser(values);
        },
    });

    const authenticate = async (formData: LoginForm) => {
        setUsername(formData.email);

        try {
            const loginData = await Auth.signIn(formData.email, formData.password);

            if (loginData) {
                dispatch(login());
                navigate("/dashboard");
            }
        } catch (error: any) {
            if (error.code === "NotAuthorizedException") {
                showAlertMessage("danger", loginNotAuthorized.message);
            } else if (error.code === "UserNotConfirmedException") {
                resendVerificationCode(formData.email);
            }
        }
        setIsLoggingIn(false);
    };

    const resendVerificationCode = async (email: string) => {
        try {
            const resendCode = await Auth.resendSignUp(email);
            if (resendCode) {
                showAlertMessage("info", codeVerification.message);
                setShowVerificationForm(true);
            }
        } catch (error) {
            showAlertMessage("danger", somethingWentWrong.message);
        }
    };

    const verifyUser = async (formData: VerifyAccountForm) => {
        try {
            const verificationData = await Auth.confirmSignUp(username!, formData.code);

            if (verificationData === "SUCCESS") {
                showAlertMessage("success", userVerified.message);
                setShowVerificationForm(false);
            }
        } catch (error: any) {
            let message = somethingWentWrong.message;

            if (error.code === "CodeMismatchException") {
                message = codeMismatch.message;
            }

            showAlertMessage("danger", message);
        }

        setIsVerifying(false);
    };

    const showAlertMessage = (variant: string, message: string) => {
        setMessageVariant(variant);
        setShowMessage(true);
        setMessage(message);
    };

    const dismissError = () => {
        setShowMessage(false);
        setMessage(null);
    };

    const loginWithGoogle = () => {
        try {
            Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
        } catch (error) {
            toast(somethingWentWrong.message, {
                type: toast.TYPE.ERROR,
                containerId: "toast-message"
            });
        }
    };

    return (
        <div id="login" className="login">
            <div className="container">
                <div className="login-page">
                    <div className="login-content">
                        <div className="login-header-section">
                            <p className="login-title">Unlock Your Home Search Journey </p>
                            <p className="login-subtitle">Sign in to your account and access a world of features designed to help you find your perfect home.
                            </p>
                        </div>

                        <div className="login-custom-right-section">
                            <div className="login-form">
                                <CustomAlert
                                    show={showMessage}
                                    variant={messageVariant!}
                                    message={message!}
                                    onClose={dismissError}
                                />

                                {!showVerificationForm && <form onSubmit={loginForm.handleSubmit}>
                                    <EmailInput
                                        id="email"
                                        name="email"
                                        placeholder="Enter email"
                                        labelText="Email"
                                        showLabel={true}
                                        errors={loginForm.errors}
                                        touched={loginForm.touched}
                                        testId="email"
                                        handleChange={(e) => loginForm.handleChange(e)}
                                    />

                                    <PasswordInput
                                        id="password"
                                        name="password"
                                        placeholder="Enter password"
                                        labelText="Password"
                                        showLabel={true}
                                        handleChange={(e) => loginForm.handleChange(e)}
                                        errors={loginForm.errors}
                                        touched={loginForm.touched}
                                        testId="password"
                                    />

                                    {/* <Link
                                        to="/forgot-password"
                                        className="forgot-password-link">
                                        Forgot password?
                                    </Link> */}

                                    <PrimaryButton
                                        type="submit"
                                        buttonText="Login"
                                        variant="primary"
                                        disabled={isLoggingIn}
                                        isLoading={isLoggingIn}
                                        customClassNames="login-button"
                                    />
                                </form>}

                                {showVerificationForm && <form onSubmit={verifyForm.handleSubmit}>
                                    <TextInput
                                        id="code"
                                        name="code"
                                        placeholder="Enter code"
                                        labelText="Verification code"
                                        showLabel={true}
                                        errors={verifyForm.errors}
                                        touched={verifyForm.touched}
                                        testId="code"
                                        handleChange={(e) => verifyForm.handleChange(e)}
                                    />

                                    <PrimaryButton
                                        type="submit"
                                        buttonText="Verify Account"
                                        variant="primary"
                                        disabled={isVerifying}
                                        isLoading={isVerifying}
                                        customClassNames="login-button"
                                    />
                                </form>}
                            </div>

                            <div className="divider">
                                <div className="divider-line"></div>
                                <div className="divider-text">OR</div>
                                <div className="divider-line"></div>
                            </div>

                            <div className="google-login">
                                <IconButton
                                    type="button"
                                    customClassNames="google-login-button"
                                    image={GoogleLogo} imageAltText="Google Login"
                                    buttonText="Log in with Google"
                                    buttonClick={loginWithGoogle}
                                />
                            </div>

                            <div className="create-account">
                                <Link
                                    to="/sign-up"
                                    className="create-account-link">
                                    Not registered yet? <span className="create-account-link-color">Create an account</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;