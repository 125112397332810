import { customAxios, formDataAxios } from "Configs/axios";

const getProperties = async (path: string, page: number, searchEntity: string, searchQuery: string) => {
    let queries: any = [];

    page && queries.push(`page=${page}`);
    searchEntity && queries.push(`entity=${searchEntity}`);
    searchQuery && queries.push(`searchQuery=${searchQuery}`);

    const query: string = queries.join("&");

    const axiosInstance = await customAxios();
    const data = await axiosInstance.get(`/${path}?${query}`);

    return data;
};

const getPropertyDetails = async (path: string, sef_url: string) => {
    const axiosInstance = await customAxios();
    const data = await axiosInstance.get(`/${path}/${sef_url}`);

    return data;
};

export const fetchProperties = async (page: number, searchEntity: string, searchQuery: string) => {
    return getProperties("properties", page, searchEntity, searchQuery);
};

export const fetchResidence = async (page: number, searchEntity: string, searchQuery: string) => {
    return getProperties("residence", page, searchEntity, searchQuery);
};

export const fetchPropertyDetails = async (sef_url: string) => {
    return getPropertyDetails("properties", sef_url);
};

export const fetchResidenceDetails = async (sef_url: string) => {
    return getPropertyDetails("residence", sef_url);
};

export const uploadReviewImages = async (formData: any) => {
    const axiosInstance = await formDataAxios();
    const data = await axiosInstance.post(`/properties/upload-review-images`, formData);

    return data;
};

export const postReview = async ({ sef_url, reviewData }: { sef_url: string, reviewData: any }) => {
    const axiosInstance = await customAxios();
    const data = await axiosInstance.post(`/properties/${sef_url}/review`, reviewData);

    return data;
};

export const updateReview = async ({ sef_url, reviewData }: { sef_url: string, reviewData: any }) => {
    const axiosInstance = await customAxios();
    const data = await axiosInstance.put(`/properties/${sef_url}/review`, reviewData);

    return data;
};

export const suggestCategory = async (category: string) => {
    const axiosInstance = await customAxios();
    const data = await axiosInstance.post(`/categories`, { name: category });

    return data;
};