import React from "react";
import { ToastContainer, Slide } from 'react-toastify';

// Notification
export const ToastNotification = () => {
    return <ToastContainer
        enableMultiContainer
        containerId={'toast-message'}
        position="bottom-left"
        autoClose={false}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        closeButton={true}
        transition={Slide}
        theme="light" />
};