import { useEffect, useState } from "react";
import { AppProvider } from "App/provider";
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-image-gallery/styles/scss/image-gallery.scss";
import 'sweetalert2/src/sweetalert2.scss'
import Login from "Pages/Public/Login";
import SignUp from "Pages/Public/SignUp";
import Search from "Pages/Search";
import PropertyDetails from "Pages/Details";
import { useDispatch, useSelector } from 'react-redux';
import "./styles.scss";
import { isAuthenticated } from "Api/Auth";
import { login, logout, setUsername } from "Pages/Search/slice";
import { useLocation, useNavigate } from 'react-router-dom';
import type { RootState } from '../App/store';
import { getUserSession } from 'Api/Auth';
import Settings from "Pages/Settings";
import Home from "Pages/Home";
import PublicRoute from "Layout/PublicRoute";
import ProtectedRoute from "Layout/ProtectedRoute";
import Contact from "Pages/Public/Contact";
import About from "Pages/Public/About";
// import ForgotPassword from "Pages/Public/Forgot-password";
import Attribution from "Pages/Public/Attribution";

export const App = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const authState = useSelector((state: RootState) => state.auth);
  const { isLoggedIn: loggedIn } = authState;

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const isLoggedIn = await isAuthenticated();
      setIsLoggedIn(isLoggedIn);
      isLoggedIn && dispatch(login());
      !isLoggedIn && dispatch(logout());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (loggedIn) {
        setIsLoggedIn(true);
        const { username } = await getUserSession();
        dispatch(setUsername({ username }))

      } else if (!loggedIn) {
        setIsLoggedIn(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  // Redirect the user to home page if login/sign up pages are accessed
  // after logging into the account
  useEffect(() => {
    if (isLoggedIn && (pathname === "/login"
      || pathname === "/sign-up" || pathname === "/")) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isLoggedIn]);

  return (
    <AppProvider>
      <Routes>
        <Route element={<PublicRoute />}>
          {/* Remove home & property links later */}
          <Route path="/" element={<Home />} />
          <Route path="/search" element={<Search />} />
          <Route path="/property/:sef_url" element={<PropertyDetails />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/attribution" element={<Attribution />} />
        </Route>

        <Route
          path="/dashboard"
          element={<ProtectedRoute> <Search /> </ProtectedRoute>}>
        </Route>
        <Route
          path="/property/:sef_url"
          element={<ProtectedRoute> <PropertyDetails /> </ProtectedRoute>}>
        </Route>
        <Route
          path="/settings"
          element={<ProtectedRoute> <Settings /> </ProtectedRoute>}>
        </Route>
      </Routes>
    </AppProvider>
  );
};
