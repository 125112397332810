import "./styles.scss";

const Attribution = () => {
    return (
        <section className="attribution">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="attribution-header">
                            <h1 className="attribution-title">Acknowledgements and Attribution</h1>
                            <p className="attribution-subtitle">
                                This page acknowledges and attributes the sources of all icon assets used in our web application.
                                We are committed to respecting intellectual property rights and ensuring that our application complies
                                with the licensing terms of the icon assets we use.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="attribution-section">
                            <h1>Icons8</h1>
                            <ul>
                                <li>
                                    <a target="_blank" href="https://icons8.com/icon/118466/facebook" rel="noreferrer">Facebook</a>
                                    &nbsp; icon by <a target="_blank" href="https://icons8.com" rel="noreferrer">Icons8</a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://icons8.com/icon/98960/linkedin" rel="noreferrer">LinkedIn</a>
                                    &nbsp; icon by <a target="_blank" href="https://icons8.com" rel="noreferrer">Icons8</a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://icons8.com/icon/59813/instagram" rel="noreferrer">Instagram</a>
                                    &nbsp; icon by <a target="_blank" href="https://icons8.com" rel="noreferrer">Icons8</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="attribution-section">
                            <h1>Freepik</h1>
                            <ul>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.freepik.com/free-vector/urban-landscape-with-houses-buildings_37881497.htm#fromView=search&term=house+rental&page=28&position=31&track=ais&regularType=vector">Image by brgfx</a>
                                    &nbsp; on Freepik
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.freepik.com/free-vector/business-team-planning-working-process-flat-vector-illustration-cartoon-colleagues-talking-sharing-thoughts-smiling-company-office-teamwork-workflow-concept_10606194.htm#fromView=search&term=about+us&page=5&position=10&track=ais&regularType=vector">Image by pch.vector</a>
                                    &nbsp; on Freepik
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.freepik.com/free-vector/hand-postman-putting-letter-envelope-into-open-mailbox-courier-delivering-mail-flat-vector-illustration-correspondence-communication-profession-newsletter-concept-banner-website-design_26921916.htm#fromView=search&term=postbox&page=1&position=22&track=sph&regularType=vector">Image by pch.vector</a>
                                    &nbsp; on Freepik
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.freepik.com/free-vector/organic-flat-feedback-concept_13961232.htm#fromView=search&term=rating&page=1&position=4&track=sph&regularType=vector">Image by pikisuperstar</a>
                                    &nbsp; on Freepik
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Attribution;