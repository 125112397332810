import { createSlice } from '@reduxjs/toolkit'

export const AuthSlice = createSlice({
    name: 'auth',
    initialState: {
        isLoggedIn: false,
        username: '',
        profile: null
    },
    reducers: {
        login: state => {
            state.isLoggedIn = true;
        },
        logout: state => {
            state.isLoggedIn = false;
            state.profile = null;
            state.username = ''
        },
        setUsername: (state, action) => {
            const { username } = action.payload;
            state.username = username;
        },
        setProfile: (state, action) => {
            state.profile = action.payload;
        }
    }
})

export const { login, logout, setUsername, setProfile } = AuthSlice.actions

export default AuthSlice.reducer;