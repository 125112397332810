import { useState } from "react";
import { useFormik } from 'formik';
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { CreateFormData, VerifyFormData } from "./types";
import TextInput from "Components/Common/InputFields/TextInput";
import EmailInput from "Components/Common/InputFields/EmailInput";
import PasswordInput from "Components/Common/InputFields/PasswordInput";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import IconButton from "Components/Common/Buttons/IconButton";
import GoogleLogo from "Assets/icons/google.svg";
import "./styles.scss";
import { signUpSchema, verificationSchema } from "Validations/sign-up.schema";
import {
    codeVerification, somethingWentWrong, emailExists,
    codeMismatch
} from "Messages/errorMessages";
import { userVerified } from "Messages/successMessage";
import { useNavigate } from "react-router-dom";
import CustomAlert from "Components/Common/Alert";
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { toast } from 'react-toastify';

const SignUp = () => {

    const navigate = useNavigate();

    const [accountCreated, setAccountCreated] = useState<boolean>(false);
    const [accountVerified, setAccountVerified] = useState<boolean>(false);
    const [username, setUsername] = useState<string | null>(null);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);
    const [messageVariant, setMessageVariant] = useState<string | null>(null);
    const [isRegistering, setIsRegistering] = useState<boolean>(false);
    const [isVerifying, setIsVerifying] = useState<boolean>(false);

    const signUpForm = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
        },
        validationSchema: signUpSchema,
        onSubmit: values => {
            setIsRegistering(true);
            registerUser(values);
        },
    });


    const verifyForm = useFormik({
        initialValues: {
            code: '',
        },
        validationSchema: verificationSchema,
        onSubmit: values => {
            setIsVerifying(true);
            verifyUser(values);
        },
    });

    // Register the user with email & password method
    const registerUser = async (formData: CreateFormData) => {
        setUsername(formData.email);

        try {
            const signUpData = await Auth.signUp({
                username: formData.email,
                password: formData.password,
                attributes: {
                    email: formData.email,
                    name: formData.name,
                    'custom:created_at': new Date().getTime().toString(),
                },
            });

            if (signUpData && signUpData.userSub && !signUpData.userConfirmed) {
                setAccountCreated(true);
                setUsername(formData.email);

                showAlertMessage("info", codeVerification.message);
            } else {
                setAccountCreated(false);
            }
        } catch (error: any) {
            if (error.code === "UsernameExistsException") {
                showAlertMessage("danger", emailExists.message);
            }
        }

        setIsRegistering(false);
    };

    const verifyUser = async (formData: VerifyFormData) => {
        try {
            const verificationData = await Auth.confirmSignUp(username!, formData.code);

            if (verificationData === "SUCCESS") {
                showAlertMessage("success", userVerified.message);

                setAccountVerified(true);
            }
        } catch (error: any) {
            let message = somethingWentWrong.message;

            if (error.code === "CodeMismatchException") {
                message = codeMismatch.message;
            }

            showAlertMessage("danger", message);
        }

        setIsVerifying(false);
    };

    const showAlertMessage = (variant: string, message: string) => {
        setMessageVariant(variant);
        setShowMessage(true);
        setMessage(message);
    };

    const dismissError = () => {
        setShowMessage(false);
        setMessage(null);
    };

    const redirectToLogin = () => {
        navigate("/login");
    };

    const signUpWithGoogle = () => {
        try {
            Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
        } catch (error) {
            toast(somethingWentWrong.message, {
                type: toast.TYPE.ERROR,
                containerId: "toast-message"
            });
        }
    };

    return (
        <div id="sign-up" className="sign-up">
            <div className="container">
                <div className="sign-up-page">
                    <div className="sign-up-content">
                        <div className="sign-up-header-section">
                            <p className="sign-up-title">Embark on Your Home Search Journey</p>
                            <p className="sign-up-subtitle">Create an account and unlock a seamless home search experience, tailored to your needs and preferences.</p>
                        </div>

                        <div className="sign-up-custom-right-section">
                            <div className="sign-up-form">
                                <CustomAlert
                                    show={showMessage}
                                    variant={messageVariant!}
                                    message={message!}
                                    onClose={dismissError}
                                />

                                {!accountCreated && !accountVerified &&
                                    <form onSubmit={signUpForm.handleSubmit}>
                                        <TextInput
                                            id="name"
                                            name="name"
                                            placeholder="Enter first name"
                                            labelText="Name"
                                            showLabel={true}
                                            errors={signUpForm.errors}
                                            touched={signUpForm.touched}
                                            testId="code"
                                            handleChange={(e) => signUpForm.handleChange(e)}
                                        />

                                        <EmailInput
                                            id="email"
                                            name="email"
                                            placeholder="Enter email"
                                            labelText="Email"
                                            showLabel={true}
                                            errors={signUpForm.errors}
                                            touched={signUpForm.touched}
                                            testId="email"
                                            handleChange={(e) => signUpForm.handleChange(e)}
                                        />

                                        <PasswordInput
                                            id="password"
                                            name="password"
                                            placeholder="Enter password"
                                            labelText="Password"
                                            showLabel={true}
                                            handleChange={(e) => signUpForm.handleChange(e)}
                                            errors={signUpForm.errors}
                                            touched={signUpForm.touched}
                                            testId="password"
                                        />

                                        <PrimaryButton
                                            type="submit"
                                            buttonText="Create Account"
                                            variant="primary"
                                            disabled={isRegistering}
                                            customClassNames="sign-up-button"
                                            isLoading={isRegistering}
                                        />
                                    </form>}

                                {accountCreated && !accountVerified && <form onSubmit={verifyForm.handleSubmit}>
                                    <TextInput
                                        id="code"
                                        name="code"
                                        placeholder="Enter code"
                                        labelText="Verification code"
                                        showLabel={true}
                                        errors={verifyForm.errors}
                                        touched={verifyForm.touched}
                                        testId="code"
                                        handleChange={(e) => verifyForm.handleChange(e)}
                                    />

                                    <PrimaryButton
                                        type="submit"
                                        buttonText="Verify Account"
                                        variant="primary"
                                        disabled={isVerifying}
                                        isLoading={isVerifying}
                                        customClassNames="sign-up-button"
                                    />
                                </form>}

                                {accountCreated && accountVerified && <PrimaryButton
                                    type="button"
                                    buttonText="Click here to login"
                                    variant="primary"
                                    disabled={false}
                                    buttonClick={redirectToLogin}
                                    customClassNames="sign-up-button"
                                />}
                            </div>

                            <div className="divider">
                                <div className="divider-line"></div>
                                <div className="divider-text">OR</div>
                                <div className="divider-line"></div>
                            </div>

                            <div className="google-sign-up">
                                <IconButton
                                    type="button"
                                    customClassNames="google-sign-up-button"
                                    image={GoogleLogo} imageAltText="Google Sign up"
                                    buttonText="Sign up with Google"
                                    buttonClick={signUpWithGoogle}
                                />
                            </div>

                            <div className="login">
                                <Link
                                    to="/login"
                                    className="login-link">
                                    Already registered? <span className="login-link-color"> &nbsp; Login</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;