import axios, { AxiosError, AxiosResponse } from "axios";
import { getUserSession } from "Api/Auth";
import { Auth } from 'aws-amplify';

const interceptorResolve = (response: AxiosResponse) => response
// Any status code that lie within the range of 2xx cause this function to trigger
// Do something with response data

const interceptorReject = (error: AxiosError) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response?.status === 401) {
        //Redirect
        logout();
    }

    return Promise.reject(error);
};

const logout = async () => {
    await Auth.signOut();
    window.location.href = "/login";
};

export const customAxios = async () => {
    try {
        const { accessToken } = await getUserSession();

        const axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_API_URI,
            headers: { Authorization: `${accessToken}` },
        });

        axiosInstance.interceptors.response.use(
            interceptorResolve,
            interceptorReject
        );

        return axiosInstance;
    } catch (error) {
        return axios.create({
            baseURL: process.env.REACT_APP_API_URI,
        });
    }
};

export const formDataAxios = async () => {
    const { accessToken } = await getUserSession();

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URI,
        headers: {
            Authorization: `${accessToken}`,
            "Content-Type": "multipart/form-data",
        },
    });

    axiosInstance.interceptors.response.use(
        interceptorResolve,
        interceptorReject
    );

    return axiosInstance;
};
