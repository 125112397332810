import ClipLoader from "react-spinners/ClipLoader";
import { SpinnerProps } from "./types";
import "./styles.scss";

const CustomSpinner = (props: SpinnerProps) => {

    const { color } = props;

    return <ClipLoader
        color={color ? color : "#fff"}
        loading={true}
        size={20}
        aria-label="Loading Spinner"
        data-testid="spinner"
    />
};

export default CustomSpinner;