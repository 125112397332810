import { Auth } from 'aws-amplify';

// Check whether user is authenticated or not
export const isAuthenticated = async () => {
    try {
        const session = await Auth.currentSession();
        return session.isValid();
    } catch (error) {
        return false;
    }
};

// Get the access, refresh tokens, user id
export const getUserSession = async () => {
    const session = await Auth.currentSession();

    const accessToken = session.getAccessToken().getJwtToken();
    const idToken = session.getIdToken().getJwtToken();
    const refreshToken = session.getRefreshToken().getToken();
    const tokenInfo = session.getAccessToken().decodePayload();

    return {
        accessToken,
        idToken,
        refreshToken,
        userId: tokenInfo.sub,
        username: tokenInfo.username,
    };
};