import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "./styles.scss";
import PersonalInformation from './Personal';
import Security from './Security';

const Settings = () => {
    return (
        <section className='settings'>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className='settings-header'>
                            <div className='settings-title'>Settings</div>
                            <p className='settings-subtitle'>Welcome to your settings page! 
                            Here you can manage all of your account settings, including your account information, and security settings</p>
                        </div>
                    </div>
                </div>

                <div className='settings-tab-section'>
                    <Tabs
                        defaultActiveKey="personal"
                        className="mb-3">
                        <Tab eventKey="personal" title="Personal">
                            <PersonalInformation />
                        </Tab>
                        <Tab eventKey="security" title="Security">
                            <Security />
                        </Tab>
                    </Tabs>
                </div>
            </div >
        </section >
    );
};

export default Settings;