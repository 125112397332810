export const tabData = [
    {
        eventKey: "general",
        title: "General",
        points: [
            "Overall condition of the house",
            "Structural soundness",
            "Age of the property",
            "Number of bedrooms and bathrooms",
            "Size of the house",
            "Layout of the house",
            "Natural light",
            "Noise levels",
            "Security features"
        ]
    },
    {
        eventKey: "plumbing",
        title: "Plumbing",
        points: [
            "Water pressure",
            "Hot water pressure",
            "Condition of the pipes",
            "Condition of the taps and showers",
            "Drainage system",
            "Presence of any leaks",
        ]
    },
    {
        eventKey: "electrical",
        title: "Electrical",
        points: [
            "Condition of the wiring",
            "Condition of the sockets and switches",
            "Presence of any exposed wiring",
            "Presence of any overloaded sockets",
            "Presence of any smoke alarms and carbon monoxide detectors",
        ]
    },
    {
        eventKey: "heating",
        title: "Heating",
        points: [
            "Type of heating system",
            "Age of the heating system",
            "Energy efficiency of the heating system",
            "Effectiveness of the heating system",
            "Presence of any thermostats or timers",
        ]
    },
    {
        eventKey: "parking",
        title: "Parking",
        points: [
            "Number of parking spaces",
            "Type of parking (e.g., garage, driveway, on-street)",
            "Accessibility of the parking spaces",
            "Security of the parking spaces",
        ]
    },
    {
        eventKey: "kitchen",
        title: "Kitchen",
        points: [
            "Condition of the kitchen cabinets",
            "Condition of the kitchen appliances",
            "Layout of the kitchen",
            "Counter space",
            "Storage space",
            "Natural light",
        ]
    },
    {
        eventKey: "boiler",
        title: "Boiler",
        points: [
            "Type of boiler",
            "Age of the boiler",
            "Energy efficiency of the boiler",
            "Condition of the boiler",
        ]
    },
    {
        eventKey: "mould",
        title: "Mould",
        points: [
            "Presence of any mould",
            "Location of any mould",
            "Extent of any mould problem",
        ]
    },
    {
        eventKey: "ventilation",
        title: "Ventilation",
        points: [
            "Type of ventilation system",
            "Effectiveness of the ventilation system",
            "Presence of any extractor fans",
        ]
    },
    {
        eventKey: "recommended-services",
        title: "Recommended Services",
        points: [
            "Convenience stores: How close is the house to a convenience store?",
            "Supermarkets: How close is the house to a supermarket?",
            "Local council support: How close is the house to the local council office?",
            "Access to parks and recreation: Is the house close to any parks, recreation centers, or other outdoor amenities?",
            "Schools: How close is the house to schools?",
            "Public transportation: How close is the house to public transportation?",
            "Healthcare: How close is the house to hospitals, doctors' offices, and other healthcare facilities?",
            "Community services: Are there any community services available in the area, such as libraries, churches, or sports clubs?",
            "Crime rates: What are the crime rates in the area?",
        ]
    }
];