import "./styles.scss";
import Skeleton from 'react-loading-skeleton';

const PropertyCardSkeleton = () => {

    return (
        <div
            className="property-skeleton">
            <div className="result-item">
                <div className="detail-section">
                    <div className="top-section">
                        <h5 className="property-name">
                            <Skeleton containerClassName="flex-1" />
                        </h5>
                    </div>

                    <p className="property-location">
                        <Skeleton />
                    </p>

                    {/* <div className="bottom-section">
                        <div className="property-star-rating">
                            <Skeleton width={70} height={20} />
                        </div>
                        <div className="property-rating">
                            <Skeleton width={70} height={60}/>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default PropertyCardSkeleton;