import { SearchResultHeaderProps } from "./types";

const SearchResultHeader = (props: SearchResultHeaderProps) => {

    const { showThoroughfare, thoroughfare, totalProperties, longAddress } = props;

    return (
        <div className="search-result-header">
            <div className="search-result-location">
                <p className="location-short">
                    {showThoroughfare && thoroughfare}
                    {!showThoroughfare && longAddress ? longAddress : ""}
                </p>
                <div className="location-long">
                    {longAddress ? longAddress : ""}
                </div>
            </div>

            <div className="search-result-stats">
                <div className="result-count">{totalProperties ? totalProperties : 0}</div>
                <div className="result-text">Properties</div>
            </div>
        </div>
    );
};

export default SearchResultHeader;