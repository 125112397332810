// import { searchOptions } from "Pages/Home/helper";
// import Select from 'react-select';
import CloseIcon from "Assets/icons/close.svg";
import SearchIcon from "Assets/icons/search.svg";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
// import { searchOptionStyles } from "Pages/Home/helper";
import { SearchFormProps } from "./types";
import "./styles.scss";
import { Fragment } from "react";

const SearchForm = (props: SearchFormProps) => {

    const { searchQuery, value, clearSearch, handleChange, handleSubmit
        // handleEntityChange, searchEntity
    } = props;

    return (
        <Fragment>
            <form className="search-form d-none d-lg-block" onSubmit={handleSubmit}>
                <img src={SearchIcon} className="search-icon" alt="Search" />

                {/* <div className="search-dropdown-container">
                    <Select
                        defaultValue={searchOptions[1]}
                        value={searchEntity ? searchOptions.find(item => item.value === searchEntity)
                            : searchOptions[0]}
                        options={searchOptions}
                        styles={searchOptionStyles}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        onChange={(e) => handleEntityChange(e as { value: string; label: string; })}
                    />
                </div> */}

                <input
                    id="query"
                    name="query"
                    type="text"
                    className="form-control custom-form-control search-input"
                    onChange={handleChange}
                    value={value}
                    placeholder="Search by Postal Code"
                />

                {searchQuery.length > 0 && <div className="d-none d-lg-block">
                    <img src={CloseIcon}
                        onClick={clearSearch}
                        alt="Clear"
                        className="clear-search"
                    />
                </div>}

                <PrimaryButton
                    type="submit"
                    buttonText="Search"
                    variant="primary"
                    disabled={false}
                    customClassNames="home-search-button"
                />

                {searchQuery.length > 0 && <PrimaryButton
                    type="button"
                    buttonText="Clear"
                    variant="primary"
                    buttonClick={clearSearch}
                    disabled={false}
                    customClassNames="d-block d-sm-none home-clear-button"
                />}
            </form>

            <form className="search-form-mobile d-block d-sm-block d-md-block d-lg-none" onSubmit={handleSubmit}>
                <div className="container">
                    <div className="row">
                        {/* <div className="col-12 col-md-6">
                            <div className="search-dropdown-container">
                                <Select
                                    defaultValue={searchOptions[1]}
                                    value={searchEntity ? searchOptions.find(item => item.value === searchEntity)
                                        : searchOptions[0]}
                                    options={searchOptions}
                                    // styles={searchOptionStyles}
                                    classNames={{
                                        control: () => "mobile-entity-control"
                                    }} components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    onChange={(e) => handleEntityChange(e as { value: string; label: string; })}
                                />
                            </div>
                        </div> */}

                        <div className="col-12 col-md-12">
                            <input
                                id="query"
                                name="query"
                                type="text"
                                className="form-control custom-form-control search-input"
                                onChange={handleChange}
                                value={value}
                                placeholder="Search by Postal Code"
                            />
                        </div>

                        <div className="col-12">
                            <PrimaryButton
                                type="submit"
                                buttonText="Search"
                                variant="primary"
                                disabled={false}
                                customClassNames="home-search-button"
                            />
                        </div>

                        {searchQuery.length > 0 && <div className="col-12">
                            <PrimaryButton
                                type="button"
                                buttonText="Clear"
                                variant="primary"
                                buttonClick={clearSearch}
                                disabled={false}
                                customClassNames="home-clear-button"
                            />
                        </div>}
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default SearchForm;