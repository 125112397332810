import React from "react";
import Alert from 'react-bootstrap/Alert';
import { AlertProps } from "./types";
import "./styles.scss";

const CustomAlert = (props: AlertProps) => {

    const { show, title, message, variant, onClose } = props;

    return (
        <Alert
            show={show}
            variant={variant ? variant : "info"}
            onClose={() => onClose!()}
            dismissible>
            <Alert.Heading>{title ? title : ""}</Alert.Heading>
            <p className="alert-message"> {message} </p>
        </Alert>
    );
};

export default CustomAlert;