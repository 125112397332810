import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useLocation, Link, useNavigate } from "react-router-dom";
import { links } from "./links";
import { Auth } from 'aws-amplify';
import "./styles.scss";
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../../App/store';
import { logout } from "Pages/Search/slice";
import { toast } from 'react-toastify';
import { logoutFailed } from "Messages/errorMessages";
import Dropdown from 'react-bootstrap/Dropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

const Header = () => {

    const { profile, isLoggedIn } = useSelector((state: RootState) => state.auth);

    const { pathname } = useLocation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logoutUser = async () => {
        try {
            await Auth.signOut();

            setTimeout(() => {
                navigate("/login");
                dispatch(logout());
            }, 1500);
        } catch (error) {
            toast(logoutFailed.message, {
                type: toast.TYPE.ERROR,
                containerId: "toast-message"
            });
        }
    };

    const gotoPage = (page: string) => {
        let checkDiv = document.getElementById('custom-offcanvas-header');
        const button = checkDiv!.querySelector('button');
        button!.click();
        navigate(page);
    };

    return (
        <Navbar collapseOnSelect expand="lg">
            <Container>
                <Navbar.Brand>
                    <Link to={isLoggedIn ? "/dashboard" : "/"}>ratemyghar</Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="offcanvasNavbar-expand" />
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand`}
                    aria-labelledby={`offcanvasNavbarLabel-expand`}
                    placement="end"
                    className="d-block d-lg-none">
                    <Offcanvas.Header id="custom-offcanvas-header" closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
                            {isLoggedIn && profile ? profile!["name"] : ""}
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            {!isLoggedIn && <Nav>
                                {links.map((item, idx) => {
                                    return <Link
                                        to={item.path}
                                        key={`nav-link-${idx}`}
                                        className={`nav-link ${pathname === item.path ? "active-link" : ""}`}>
                                        {item.title}
                                    </Link>
                                })}
                            </Nav>}
                            {isLoggedIn && <Nav>
                                <p onClick={() => gotoPage("/dashboard")}>Dashboard</p>
                                <p onClick={() => gotoPage("/settings")}>Settings</p>
                                <p onClick={logoutUser}>Logout</p>
                            </Nav>}
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>

                <Navbar.Collapse id="responsive-navbar-nav" className='d-none d-xl-block'>
                    <Nav className="me-auto"> </Nav>
                    {!isLoggedIn && <Nav>
                        {links.map((item, idx) => {
                            return <Link
                                to={item.path}
                                key={`nav-link-${idx}`}
                                className={`nav-link ${pathname === item.path ? "active-link" : ""}`}>
                                {item.title}
                            </Link>
                        })}
                    </Nav>}
                    {isLoggedIn && <Nav>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary">
                                {profile ? profile!["name"] : ""}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => gotoPage("/settings")}>Settings</Dropdown.Item>
                                <Dropdown.Item onClick={logoutUser}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;