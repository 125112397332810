import { Link } from "react-router-dom";
import LinkedinIcon from "Assets/icons/linkedin.svg";
import FaceBookIcon from "Assets/icons/facebook.svg";
import InstagramIcon from "Assets/icons/instagram.svg";
import "./styles.scss";

const Footer = () => {
    return (
        <div id="footer" className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-3">
                        <div className="rights-section">
                            <div className="rights-title">RateMyGhar</div>
                            <div className="rights-text">&copy; 2023 All Rights Reserved</div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="link-section">
                            <p className="link-title">Useful Links</p>
                            <ul>
                                <li>
                                    <Link to="/about">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="link-section">
                            <p className="link-title">Legal</p>
                            <ul>
                                <li>
                                    <Link to="">Terms & Conditions</Link>
                                </li>
                                <li>
                                    <Link to="">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/attribution">Attribution</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="follow-us">
                            <p className="link-title">Follow Us</p>
                            <ul>
                                <li>
                                    <Link to="https://www.linkedin.com/company/ratemyghar">
                                        <div className="follow-icon">
                                            <img src={LinkedinIcon} alt="LinkedIn" />
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.facebook.com/ratemyghar">
                                        <div className="follow-icon">
                                            <img src={FaceBookIcon} alt="FaceBook" />
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/ratemyghar">
                                        <div className="follow-icon">
                                            <img src={InstagramIcon} alt="Instagram" />
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;