import { Fragment } from "react";
import { EmailInputProps } from "./types";
import "../styles.scss";

const EmailInput = (props: EmailInputProps) => {

    const { id, name, placeholder, showLabel, labelText, value, disabled,
        errors, touched, testId, customClasses, handleChange } = props;

    return (
        <Fragment>
            {showLabel && <label
                className="input-label-text"
                htmlFor={id}>
                {labelText}
            </label>}

            <input
                id={id}
                name={name}
                type="email"
                disabled={disabled}
                placeholder={placeholder}
                data-testid={testId}
                className={`form-control custom-form-control ${customClasses ? customClasses : ""}`}
                onChange={(e) => handleChange(e)}
                value={value}
            />

            {errors && touched && errors[name] && touched[name] ? (
                <div className="input-field-error">{errors[name]}</div>
            ) : null}
        </Fragment>
    );
};

export default EmailInput;