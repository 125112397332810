import { Fragment, useEffect, useState } from "react";
// import ReactStars from 'react-stars';
import "./styles.scss";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PostReviewModal from "./Components/PostReviewModal";
import type { RootState } from '../../App/store';
import { useSelector } from 'react-redux';
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from 'react-router-dom';
import { fetchPropertyDetails, fetchResidenceDetails } from "Api/Property";
import { Property } from "./types";
import { timestampToDate } from "Helpers";
import { tabData } from "./data";
import Accordion from 'react-bootstrap/Accordion';
import PropertyDetailSkeleton from "./Components/Skeleton";
import ReviewSection from "./Components/ReviewSection";

const PropertyDetails = () => {

    const { isLoggedIn, username } = useSelector((state: RootState) => state.auth);

    const [showPostReviewModal, setShowPostReviewModal] = useState<boolean>(false);
    const [hasUserReview, setHasUserReview] = useState<boolean>(false);
    const [propertyDetails, setPropertyDetails] = useState<Property>();
    const [reviews, setReviews] = useState<[]>([]);
    const [activeTab, setActiveTab] = useState("general");

    const { sef_url } = useParams();
    const navigate = useNavigate();

    const maskLimit = 1;

    const { data, isLoading, isError, refetch } = useQuery({
        queryKey: ["details"],
        queryFn: () => isLoggedIn ? fetchPropertyDetails(sef_url!) : fetchResidenceDetails(sef_url!),
        select: ({ data }) => data
    });

    useEffect(() => {
        if (data && data.property && data.property.length > 0) {
            setPropertyDetails(data.property[0]);
            showReviewsOnTabVisit("general");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const togglePostReviewModal = (action?: string) => {
        hasUserReview && navigate(`/property/${sef_url}`);
        setShowPostReviewModal(!showPostReviewModal);

        if (showPostReviewModal) {
            hasUserReview && navigate(`/property/${sef_url}`);
        }

        if (action && action === "close") {
            refetch();
        }
    };

    const redirectToLogin = () => {
        navigate("/login");
    };

    const showReviewsOnTabVisit = (activeKey: string) => {
        setActiveTab(activeKey!);

        if (data.property && data.property[0].reviews) {
            const reviewsByCategory: any = {};

            const filteredReviews = data.property[0].reviews.filter((review: any) => review.category === activeKey);
            const hasUserReview = data.property[0].reviews.filter((review: any) => review.user_id === username);
            setHasUserReview(hasUserReview.length > 0 ? true : false);

            filteredReviews.forEach((review: any) => {
                reviewsByCategory[review.category] = review;
            });

            setReviews(filteredReviews);
        }
    };

    return (
        <Fragment>
            {/* Replace the following with page skeletons */}
            {isError && !isLoading && <div className="details-loading-failed">Failed to load property details</div>}

            {!isError && isLoading && <PropertyDetailSkeleton />}

            {!isError && !isLoading && <div id="details">
                <div className="container">
                    <div className="details">
                        <div className="details-header">
                            <div className="details-location">
                                <p className="location-short">{propertyDetails && propertyDetails.line_1}</p>
                                <div className="location-long">
                                    <span>{propertyDetails && propertyDetails.town_or_city}, </span>
                                    {propertyDetails && propertyDetails.county && <span>{propertyDetails.county}, </span>}
                                    <span>{propertyDetails && propertyDetails.country}</span>
                                </div>

                                <div className="last-review-date">
                                    Recent activity on {propertyDetails && timestampToDate(propertyDetails && propertyDetails.updated_at, "dateTime")}
                                </div>
                            </div>

                            <div className="details-stats">
                                {/* <div className="rating-value">{propertyDetails && propertyDetails.rating ? propertyDetails.rating : 0}</div>
                                <ReactStars
                                    count={5}
                                    size={20}
                                    value={propertyDetails && propertyDetails.rating ? propertyDetails.rating : 0}
                                    edit={false}
                                    color2={'#ffd700'} /> */}
                                <p className="score-text">Score will be available once there are enough reviews</p>
                            </div>
                        </div>

                        <div className="post-review-button-section">
                            <div className="text-section">
                                <h5 className="title">Reviews</h5>
                                <p className="content">Share your genuine review of this property to help other potential buyers or renters make informed decisions.
                                    Your honest feedback is invaluable, and it can make a real difference in someone else's home search journey.</p>
                            </div>
                            <div className="button-section">
                                {isLoggedIn && <PrimaryButton
                                    type="button"
                                    buttonText={hasUserReview ? "View Review" : "Post Review"}
                                    disabled={false}
                                    customClassNames="post-review-button"
                                    buttonClick={() => togglePostReviewModal()}
                                />}

                                {!isLoggedIn && <PrimaryButton
                                    type="button"
                                    buttonText="Login to Post Review"
                                    disabled={false}
                                    customClassNames="post-review-button"
                                    buttonClick={redirectToLogin}
                                />}
                            </div>
                        </div>

                        <div className="accordion-section d-block d-xl-none">
                            <Accordion defaultActiveKey="general"
                                onSelect={(activeKey) => {
                                    showReviewsOnTabVisit(activeKey ? activeKey!.toString() : 'general');
                                }}>
                                {tabData && tabData.length > 0 && tabData.map((item, idx) => {
                                    return <Accordion.Item
                                        key={`accordion-${item.eventKey}`}
                                        eventKey={item.eventKey} >
                                        <Accordion.Header>{item.title}</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="accordion-review-section">
                                                <ReviewSection
                                                    reviews={reviews}
                                                    isLoggedIn={isLoggedIn}
                                                    maskLimit={maskLimit}
                                                    activeTab={activeTab}
                                                />
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                })}
                            </Accordion>
                        </div>

                        <div className="tab-section d-none d-xl-block">
                            <Tabs
                                defaultActiveKey="general"
                                id="details-tab"
                                className="mb-3"
                                onSelect={(activeKey) => {
                                    showReviewsOnTabVisit(activeKey!);
                                }}
                            >
                                {tabData && tabData.length > 0 && tabData.map((item, idx) => {
                                    return <Tab key={`tab-${item.eventKey}`} eventKey={item.eventKey} title={item.title}>
                                        <div className="review-section">
                                            <ReviewSection
                                                reviews={reviews}
                                                isLoggedIn={isLoggedIn}
                                                maskLimit={maskLimit}
                                                activeTab={activeTab}
                                            />
                                        </div>
                                    </Tab>
                                })}
                            </Tabs>
                        </div>
                    </div>
                </div>

                <PostReviewModal
                    sefUrl={sef_url!}
                    username={username}
                    show={showPostReviewModal}
                    propertyDetails={propertyDetails!}
                    closeButton={togglePostReviewModal}
                />
            </div>}
        </Fragment>
    );
};

export default PropertyDetails;