import { useReducer, useEffect, Fragment } from "react";
import "./styles.scss";
import { initialState, reducer } from "./store";
import { validateName, validateEmail } from "./helpers";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { submitContact } from "Api/Public";
import { useMutation } from "@tanstack/react-query";

const Contact = () => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const { name, email, subject, message, isValid } = state;

    const { mutate: sendContactRequest, isLoading } = useMutation(submitContact, {
        onSuccess: (data: any) => {
            if (data.status) {
                dispatch({ type: "RESET_STATE" });
                dispatch({ type: "SET_VALUE", field: "isLoading", value: false });

                toast.success("Thank you for contacting RateMyGhar", {
                    position: 'bottom-left',
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            } else {
                showErrorMessage();
                dispatch({ type: "SET_VALUE", field: "isLoading", value: false });
            }

        },
        onError: () => {
            showErrorMessage();
            dispatch({ type: "SET_VALUE", field: "isLoading", value: false });
        },
    });

    useEffect(() => {
        if (
            validateName(name) &&
            validateEmail(email) &&
            subject.length > 0 &&
            message.length > 0
        ) {
            dispatch({ type: "SET_VALUE", field: "isValid", value: true });
        } else {
            dispatch({ type: "SET_VALUE", field: "isValid", value: false });
        }
    }, [name, email, subject, message]);

    const onChange = (field: string, value: string) => {
        dispatch({ type: "SET_VALUE", field: field, value: value });
    };

    const sendMessage = () => {
        if (isValid) {
            dispatch({ type: "SET_VALUE", field: "isLoading", value: true });
            const data = { name: name, email: email, subject: subject, message: message }
            sendContactRequest(data);
        }
    };

    const showErrorMessage = () => {
        toast.error("Something went wrong. Please try again later", {
            position: 'bottom-left',
            autoClose: 3000,
            hideProgressBar: true,
        });
    };

    return (
        <Fragment>
            <section id="contact" className="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="header">
                                <h1 className="title">
                                    Contact Us
                                </h1>
                                <p className="subtitle">
                                    Have questions or need assistance? Send us an email or connect with us on social media. We&apos;re here to help.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 contactFormColumn">
                            <div className="contactForm">
                                <form className="row formContainer">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control inputField"
                                            placeholder="Name"
                                            value={name}
                                            onChange={(e) => onChange("name", e.target.value)}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <input type="text"
                                            className="form-control inputField"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => onChange("email", e.target.value)}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <input type="text"
                                            className="form-control inputField"
                                            placeholder="Subject"
                                            value={subject}
                                            onChange={(e) => onChange("subject", e.target.value)}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <textarea
                                            rows={5}
                                            cols={6}
                                            className="form-control inputField messageInput"
                                            placeholder="Message"
                                            value={message}
                                            onChange={(e) => onChange("message", e.target.value)}
                                        ></textarea>
                                    </div>
                                    <div className="col-auto mx-auto">
                                        <button
                                            type="button"
                                            disabled={false}
                                            onClick={() => sendMessage()}
                                            className={`sendButton ${!isValid && "disabled"}`}>
                                            {isLoading ? "Sending..." : "Send Message"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 followSectionColumn">
                            <div className="rightSection">
                                <div className="rightSectionTitle">
                                    Connect with Us
                                </div>
                                <div className="rightSectionContent">
                                    <p className="rightSectionContentText">
                                        Connect with us on social media to stay up-to-date on the latest news and updates.
                                    </p>
                                    <div className="linkContainer">
                                        <div>
                                            <div className="labelBox">
                                                <div className="label">Email</div>
                                                <div className="labelValue">
                                                    <FontAwesomeIcon icon={faEnvelope} className="contactIcon" />
                                                    <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
                                                        className="socialLink">{process.env.REACT_APP_CONTACT_EMAIL}</a>
                                                </div>
                                            </div>
                                            <div className="labelBox">
                                                <div className="label">LinkedIn</div>
                                                <div className="labelValue">
                                                    <FontAwesomeIcon icon={faLinkedin} className="contactIcon" />
                                                    <a
                                                        href="https://www.linkedin.com/company/ratemyghar"
                                                        target="_blank"
                                                        className="socialLink"
                                                        rel="noreferrer">
                                                        {process.env.REACT_APP_SOCIAL_HANDLE}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="labelBox">
                                                <div className="label">Facebook</div>
                                                <div className="labelValue">
                                                    <FontAwesomeIcon icon={faFacebook} className="contactIcon" />
                                                    <a
                                                        href="https://www.facebook.com/ratemyghar"
                                                        target="_blank"
                                                        className="socialLink"
                                                        rel="noreferrer">
                                                        {process.env.REACT_APP_SOCIAL_HANDLE}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="labelBox">
                                                <div className="label">Instagram</div>
                                                <div className="labelValue">
                                                    <FontAwesomeIcon icon={faInstagram} className="contactIcon" />
                                                    <a
                                                        href="https://www.instagram.com/ratemyghar"
                                                        target="_blank"
                                                        className="socialLink"
                                                        rel="noreferrer">
                                                        {process.env.REACT_APP_SOCIAL_HANDLE}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </Fragment>
    );
};

export default Contact;