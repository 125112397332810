import React, { useEffect, useState } from "react";
import ReactStars from 'react-stars';
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import "./styles.scss";
import { ReviewFormTabContentProps, UpdateReviewProps, CreateReviewProps } from "./types";
import { useMutation } from "@tanstack/react-query";
import { postReview, uploadReviewImages, updateReview } from "Api/Property";
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';

const ReviewFormTabContent = (props: ReviewFormTabContentProps) => {

    const { onBackButtonClick, onNextButtonClick, sefUrl, promptOnClose, tabData,
        showBackButton, showNextButton, username, activeTab, reviews } = props;

    const [uploadedFileNames, setUploadedFileNames] = useState<string[]>([]);
    let [reviewImages, setReviewImages] = useState<[]>([]);
    const [rating, setRating] = useState<number>(0);
    const [comment, setComment] = useState<string>('');
    const [review, setReview] = useState<UpdateReviewProps>();
    const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
    const [files, setFiles] = useState<any>([]);
    const [followNotes, setFollowNotes] = useState<any>([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const { mutate: submitReview, isLoading: isPostingReview } = useMutation(postReview, {
        onSuccess: () => {
            setUploadedFileNames([]);
            toast("Review added", {
                type: toast.TYPE.SUCCESS,
                containerId: "toast-message",
                autoClose: 3000
            });

            onNextButtonClick!();
        },
        onError: () => {
            toast("Failed to post review", {
                type: toast.TYPE.ERROR,
                containerId: "toast-message",
                autoClose: 3000
            });
        },
    });

    const { mutate: modifyReview, isLoading: isUpdatingReview } = useMutation(updateReview, {
        onSuccess: (response) => {
            setUploadedFileNames([]);
            toast("Review updated", {
                type: toast.TYPE.SUCCESS,
                containerId: "toast-message",
                autoClose: 3000
            });

            onNextButtonClick!();
        },
        onError: () => {
            toast("Failed to update review", {
                type: toast.TYPE.ERROR,
                containerId: "toast-message",
                autoClose: 3000
            });
        },
    });

    const { mutate: uploadImage, isLoading: isUploadingImage } = useMutation(uploadReviewImages, {
        onSuccess: (response) => {
            setUploadedFileNames(response.data.fileNames);
            toast("Review image uploaded", {
                type: toast.TYPE.SUCCESS,
                containerId: "toast-message",
                autoClose: 3000
            });
        },
        onError: (err) => {
            toast("Failed to upload images.", {
                type: toast.TYPE.ERROR,
                containerId: "toast-message",
                autoClose: 3000
            });
        },
    });

    const handleRemoveFile = (file: File) => {
        setFiles((prevFiles: any[]) => prevFiles.filter((prevFile) => prevFile !== file));
    };

    const handleRemoveImage = (file: File) => {
        const updatedFiles: any = reviewImages.filter(image => image !== file);
        setReviewImages(updatedFiles);
    };

    const handleRatingChange = (score: number) => {
        setRating(score);
    };

    const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(event.target.value)
    };

    const determineReviewUpdate = () => {
        if (review && (Number(review.score) !== Number(rating)
            || review.comment !== comment || files.length > 0
            || review.images !== reviewImages)) {
            return true;
        }

        return false;
    };

    const determineShouldCreateReview = () => {
        return !review ? true : false;
    };

    const saveReview = () => {
        if (rating > 0 && comment.length > 0) {
            const shouldUpdateReview = determineReviewUpdate();
            const shouldCreateReview = determineShouldCreateReview();

            if (shouldUpdateReview) {
                const reviewData: UpdateReviewProps = {
                    category: activeTab,
                    score: rating.toString(),
                    comment: comment.toString(),
                    user: username,
                    _id: review!._id
                };

                if (uploadedFileNames.length > 0) {
                    let uploadImages = [
                        ...reviewImages,
                        ...uploadedFileNames
                    ];

                    reviewData.images = uploadImages;
                } else {
                    reviewData.images = reviewImages;
                }

                modifyReview({ sef_url: sefUrl, reviewData });
            }
            else if (shouldCreateReview) {
                const reviewData: CreateReviewProps = {
                    category: activeTab,
                    score: rating.toString(),
                    comment: comment.toString(),
                    user: username
                };

                if (uploadedFileNames.length > 0) {
                    reviewData.images = uploadedFileNames
                }

                submitReview({ sef_url: sefUrl, reviewData });
            } else {
                onNextButtonClick!();
            }
        }
    };

    useEffect(() => {
        if (tabData && activeTab) {
            const tabInfo = tabData.find((item: any) => item.eventKey === activeTab);

            if (tabInfo) {
                setFollowNotes(tabInfo.points);
            }
        }
    }, [tabData, activeTab]);

    useEffect(() => {
        if (files && files.length > 0) {
            const formData = new FormData();
            files.forEach((file: File) => {
                formData.append(`images`, file);
            });

            uploadImage(formData);
            promptOnClose(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    // Set review data when showing the post review section
    useEffect(() => {
        if (reviews && reviews.length > 0) {
            const review: any = reviews.filter((item: any) => item.category === activeTab);

            if (review && review.length >= 1 && review[0].user_id === username) {
                setReview(review[0]);
                setRating(Number(review[0].score));
                setComment(review[0].comment);

                if (review[0].images && review[0].images.length > 0) {
                    const images = review[0].images;
                    if (images && images.length > 0) {
                        setReviewImages(images);
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviews]);

    useEffect(() => {
        if ((rating === 0 || comment.length === 0) || (isPostingReview ||
            isUpdatingReview || isUploadingImage)) {
            setDisableSaveButton(true);
        } else {
            setDisableSaveButton(false);
        }
    }, [rating, comment, isPostingReview, isUpdatingReview, isUploadingImage]);

    useEffect(() => {
        if (rating > 0 || comment.length > 0) {
            const shouldUpdateReview = determineReviewUpdate();
            const shouldCreateReview = determineShouldCreateReview();

            if (shouldUpdateReview || shouldCreateReview) {
                promptOnClose(true);
            } else {
                promptOnClose(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rating, comment]);

    const thumbs = files.map((file: any) => (
        <div className="thumb" key={file.name}>
            <div className='thumbInner'>
                <span
                    onClick={() => !isUploadingImage && handleRemoveFile(file)}
                    className="remove-button">X</span>
                <img
                    src={file.preview}
                    className='img'
                    alt=""
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    const preview = reviewImages && reviewImages.map((file: any, idx: number) => (
        <div className="thumb" key={`preview-${idx}`}>
            <div className='thumbInner'>
                <span
                    onClick={() => !isUploadingImage && handleRemoveImage(file)}
                    className="remove-button">X</span>
                <img
                    src={file}
                    className='img'
                    alt=""
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    return (
        <div className="form-tab-content-item">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="info-section">
                            <div className="info-title">What should be considered to rate
                                {activeTab === "recommended-services" ?
                                    ` ${activeTab.replace("-", " ")}`
                                    : ` ${activeTab}`} section?</div>
                            <p className="info-text">{followNotes.join(", ")}</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="input-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-7">
                                        <div className="label-text">
                                            Please rate on a scale of 5
                                            <span className="required-field">*</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5">
                                        <div className="rating-section">
                                            <ReactStars
                                                count={5}
                                                size={24}
                                                value={rating}
                                                color2={'#ffd700'}
                                                onChange={handleRatingChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="comment-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-md-12">
                                            <div className="label-text">
                                                Leave some comments
                                                <span className="required-field">*</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <textarea
                                                className="form-control"
                                                rows={5}
                                                value={comment}
                                                placeholder="Comments"
                                                onChange={handleCommentChange}
                                            >{comment}</textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="image-upload-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-md-12">
                                            <div className="label-text">Upload Images</div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <section>
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                                </div>
                                                <aside className="thumbsContainer">
                                                    {thumbs}
                                                    {preview}
                                                </aside>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="button-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-lg-3 col-xl-3">
                                            {showBackButton && <PrimaryButton
                                                type="button"
                                                buttonText="Back"
                                                variant="primary"
                                                disabled={false}
                                                customClassNames="back-button"
                                                buttonClick={() => onBackButtonClick!()}
                                            />}

                                            {showNextButton && <PrimaryButton
                                                type="button"
                                                buttonText={determineReviewUpdate() ? "Save Changes" : "Save"}
                                                variant="primary"
                                                disabled={disableSaveButton}
                                                isLoading={isPostingReview || isUploadingImage || isUpdatingReview}
                                                customClassNames="next-button"
                                                buttonClick={saveReview}
                                            />}
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewFormTabContent;