import { useEffect, useState, Fragment } from "react";
import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from "react-router-dom";
import "./styles.scss";
import { useSelector } from 'react-redux';
import type { RootState } from '../../App/store';
import { fetchProperties, fetchResidence } from "Api/Property";
import { Property } from "./types";
import Pagination from 'react-bootstrap/Pagination';
import { useQuery } from "@tanstack/react-query";
import SearchForm from "./Components/SearchForm";
import PropertyCard from "./Components/PropertyCard";
import SearchResultHeader from "./Components/SearchResultHeader";
import Skeleton from 'react-loading-skeleton';
import PropertyCardSkeleton from "./Components/PropertyCard Skeleton";

const Home = () => {

    const { isLoggedIn } = useSelector((state: RootState) => state.auth);

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [isSearch, setIsSearch] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [paginationItems, setPaginationItems] = useState<JSX.Element[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchEntity, setSearchEntity] = useState<string>("postCode");
    const [showThoroughfare, setShowThoroughfare] = useState<boolean>(false);
    const [totalProperties, setTotalProperties] = useState<number>(0);
    const [longAddress, setLongAddress] = useState<string>('');
    const [thoroughfare, setThoroughfare] = useState<string>('');
    const [propertyData, setPropertyData] = useState<Property[]>([]);

    const { data, isLoading, isError } = useQuery({
        queryKey: ["property-list", [page, searchEntity, searchQuery]],
        queryFn: () => isLoggedIn ?
            fetchProperties(page, searchEntity, searchQuery) : fetchResidence(page, searchEntity, searchQuery),
        enabled: true,
        select: ({ data }) => data
    });

    const totalPages: number = data && data.totalPages;

    const formik = useFormik({
        initialValues: {
            query: ''
        },
        onSubmit: values => {
            handleSearch(values);
        },
    });

    useEffect(() => {
        const query: string = searchParams.get("query")!;
        const entity: string = searchParams.get("entity")!;

        if (query && query.length > 0) {
            setIsSearch(true);
            setSearchQuery(query);
            setSearchEntity(entity);
            formik.values.query = query;
        } else {
            setIsSearch(false);
            setSearchQuery("");
            formik.values.query = '';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    // Pagination - buttons
    useEffect(() => {
        if (data) {
            let items: JSX.Element[] = [];
            const ellipsisItem = <Pagination.Ellipsis key="ellipsis" onClick={handleEllipsisClick} />;

            items.push(
                <Pagination.First key="first" onClick={() => setPage(1)} />,
                <Pagination.Prev key="prev" onClick={() => setPage(page - 1)} disabled={page === 1} />
            );

            // Ellipsis for long lists
            if (page > 4) {
                items.push(ellipsisItem);
            }

            for (let i: number = page - 2; i <= page + 2; i++) {
                if (i > 0 && i <= totalPages) {
                    items.push(
                        <Pagination.Item
                            key={i}
                            active={i === page}
                            onClick={() => setPage(i)}
                        >
                            {i}
                        </Pagination.Item>
                    );
                }
            }

            if (page < totalPages - 3) {
                items.push(ellipsisItem);
            }

            items.push(
                <Pagination.Next
                    key="next"
                    onClick={() => setPage(page + 1)}
                    disabled={page === totalPages}
                />,
                <Pagination.Last
                    key="last"
                    onClick={() => setPage(totalPages)}
                    disabled={page === totalPages}
                />
            );

            setPaginationItems(items);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, page]);

    // Should display street name based on search query
    useEffect(() => {
        if (data && data.properties && data.properties.length > 0) {
            setPropertyData(data.properties);
            setTotalProperties(data.totalItems);

            let showThoroughfare: boolean = true;

            const thoroughfare: string = data.properties[0].thoroughfare;
            setThoroughfare(thoroughfare);

            for (let i = 0; i < data.properties.length; i++) {
                if (data.properties[i].thoroughfare !== thoroughfare) {
                    showThoroughfare = false;
                    break;
                }
            }

            if (showThoroughfare) {
                const address = `${data.properties[0].town_or_city}, ${data.properties[0].county}, ${data.properties[0].country}`
                setShowThoroughfare(true);
                setLongAddress(address);
            } else {
                const address = `${data.properties[0].county}, ${data.properties[0].country}`
                setShowThoroughfare(false);
                setLongAddress(address);
            }
        } else if (data && data.properties && data.properties.length === 0) {
            setPropertyData([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoading]);

    // Pagination
    const handleEllipsisClick = () => {
        const nextPage: number = page + 3;
        setPage(nextPage > totalPages ? totalPages : nextPage);
    };

    // Clear search
    const clearSearch = () => {
        formik.values.query = '';
        navigate(isLoggedIn ? `/dashboard` : '/search');
    };

    // Handle post code/address change
    const handleEntityChange = (option: { value: string; label: string; }) => {
        setSearchEntity(option!.value);
        navigate(`?query=${searchQuery}&entity=${option!.value}`);
    };

    // Handle search operation
    const handleSearch = (values: { query: string | any[]; }) => {
        if (values.query.length > 0) {
            navigate(`?query=${values.query}&entity=${searchEntity}`);
            setPage(1);
        }

        isLoggedIn && values.query.length === 0 && navigate(`/dashboard`);
        !isLoggedIn && values.query.length === 0 && navigate(`/`);
    };

    return (
        <div id="search" className="search">
            <div className="search-content">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="search-box">
                                <h1 className="search-title">Explore Your Home Options</h1>
                                <p className="search-subtitle">Begin your home search with our simple and intuitive search feature. Enter your desired postal code and discover properties matching your criteria.</p>
                                <SearchForm
                                    searchEntity={searchEntity}
                                    searchQuery={searchQuery}
                                    value={formik.values.query}
                                    clearSearch={clearSearch}
                                    handleEntityChange={handleEntityChange}
                                    handleChange={formik.handleChange}
                                    handleSubmit={formik.handleSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {!isSearch &&
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="feature-section">
                                <div className="feature-title-section">
                                    <div className="feature-title">Browse Our Properties</div>
                                    <div className="feature-subtitle">Browse our extensive database of properties, and find the perfect home for your needs
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            {<div className="container">
                <div className="search-result">
                    {isSearch &&
                        <Fragment>
                            <h3 className="search-result-text">Search results for {searchQuery}</h3>
                            {searchEntity === "postCode" && propertyData && propertyData.length > 0 &&
                                <SearchResultHeader
                                    showThoroughfare={showThoroughfare}
                                    thoroughfare={thoroughfare}
                                    totalProperties={totalProperties}
                                    longAddress={longAddress}
                                    data={data ? propertyData : []}
                                    totalItems={data && data.totalItems ? data.totalItems : []}
                                />}
                        </Fragment>}

                    <div className="search-result-content">
                        <div className="search-result-section">
                            {propertyData && propertyData.length > 0 &&
                                propertyData.map((item: Property) => {
                                    return <PropertyCard
                                        isLoading={isLoading}
                                        key={item.sef_url}
                                        item={item} />
                                })}
                        </div>

                        {propertyData && propertyData.length > 0 &&
                            <div className="search-result-pagination">
                                <Pagination>
                                    {paginationItems}
                                </Pagination>
                            </div>}
                    </div>
                </div>
            </div>}

            {!isLoading && !isError && propertyData && propertyData.length === 0 &&
                <div className="container">
                    <div className="no-results">No properties found</div>
                </div>}

            {!isLoading && isError &&
                <div className="container">
                    <div className="no-results">There was an error fetching the data</div>
                </div>}

            {isLoading && <div className="container">
                <div className="search-result">
                    <div className="search-result-content">
                        <div className="search-result-section">
                            {[1, 2, 3, 4, 5, 6].map((item, idx) => {
                                return <PropertyCardSkeleton key={`skeleton-${idx}`} />
                            })}
                        </div>

                        <div className="search-result-pagination-skeleton">
                            <div>
                                <Skeleton height={30} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
};

export default Home;