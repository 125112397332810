import React from "react";
import { useFormik } from "formik";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import { useMutation } from "@tanstack/react-query";
import { suggestCategory } from "Api/Property";
import "./styles.scss";
import { toast } from 'react-toastify';
import { suggestCategorySuccess, suggestCategoryFailure } from "Messages/errorMessages";

const CategoryForm = () => {

    const formik = useFormik({
        initialValues: {
            category: ''
        },
        onSubmit: values => {
            addTodo(values.category);
        },
    });

    const { mutate: addTodo, isLoading } = useMutation(suggestCategory, {
        onSuccess: () => {
            toast(suggestCategorySuccess.message, {
                type: toast.TYPE.SUCCESS,
                containerId: "toast-message",
                autoClose: 3000
            });

            formik.resetForm();
        },
        onError: () => {
            toast(suggestCategoryFailure.message, {
                type: toast.TYPE.ERROR,
                containerId: "toast-message",
                autoClose: 3000
            });
        },
    });

    return (
        <div className="more-category-section">
            {/* <div className="more-category-title">Looking out for more categories?</div> */}
            <p className="more-category-text">
                Help us improve our property review website by suggesting new categories.
            </p>

            <div className="category-form-section">
                <form className="category-form" onSubmit={formik.handleSubmit}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <input
                                    id="category"
                                    name="category"
                                    type="text"
                                    className="form-control custom-form-control category-input"
                                    onChange={formik.handleChange}
                                    value={formik.values.category}
                                    placeholder="Enter category"
                                />

                            </div>
                            <div className="col-12 col-xl-3">
                                <PrimaryButton
                                    type="submit"
                                    buttonText="Submit"
                                    variant="primary"
                                    disabled={formik.values.category.length === 0}
                                    customClassNames="add-category-button"
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </form>


            </div>
        </div>
    );
};

export default CategoryForm;