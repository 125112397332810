import React from "react";
import Button from 'react-bootstrap/Button';
import { PropType } from "./types";
import "./styles.scss";
import CustomSpinner from "../../Spinner";

const PrimaryButton = (props: PropType) => {

    const { type, buttonText, variant, customClassNames,
        disabled, buttonClick, isLoading } = props;

    return (
        <Button
            type={type}
            className={customClassNames}
            disabled={disabled}
            onClick={(e) => {
                buttonClick && buttonClick!(e)
            }}
            variant={variant}>
            {isLoading ? <CustomSpinner /> : buttonText}
        </Button>
    );
};

export default PrimaryButton;