import { customAxios } from "Configs/axios";

export const fetchProfile = async () => {
    const axiosInstance = await customAxios();
    const data = await axiosInstance.get(`/settings/profile`);

    return data;
};

export const updateProfile = async (profileData: { name: string, email: string }) => {
    const axiosInstance = await customAxios();
    const data = await axiosInstance.put(`/settings/profile`, profileData);

    return data;
};

export const deleteProfile = async () => {
    const axiosInstance = await customAxios();
    const data = await axiosInstance.delete(`/settings/profile`);

    return data;
};