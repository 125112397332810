import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ChildrenType } from "App/types";

export const AppProvider = ({ children }: ChildrenType) => {
  const queryClient = new QueryClient(
    {
      defaultOptions: {
        queries: {
          retry: false
        }
      }
    }
  );

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
