import * as Yup from "yup";

export const profileSchema = Yup.object({
    email: Yup.string().email("Please enter a valid email").required("Email address is required"),
    name: Yup.string().required("Name is required")
});

const getCharacterValidationError = (str: string) => {
    return `Your password must have at least 1 ${str} character`;
};

export const passwordSchema = Yup.object({
    password: Yup.string()
        .required("Password is required")
        .min(8, "Password must have at least 8 characters"),
    newPassword: Yup.string()
        .required("Password is required")
        // check minimum characters
        .min(8, "Password must have at least 8 characters")
        // different error messages for different requirements
        .matches(/[0-9]/, getCharacterValidationError("digit"))
        .matches(/[a-z]/, getCharacterValidationError("lowercase"))
        .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
        .matches(/^(?=.*[!@#$%^&*])/, getCharacterValidationError("special")),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], 'Passwords must match')
});