export const initialState = {
    name: "",
    email: "",
    subject: "",
    message: "",
    isValid: false,
};

export const reducer = (state: any, action: any) => {
    switch (action.type) {

        case "SET_VALUE":
            return {
                ...state,
                [action.field]: action.value,
            };

        case "DISABLE_BUTTON":
            return {
                ...state,
                disableButton: action.status,
            };

        case "RESET_STATE":
            return {
                ...state,
                name: "",
                email: "",
                subject: "",
                message: "",
                isValid: false,
            };

        default:
            return state;
    }
};