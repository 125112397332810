import { Fragment, useState } from "react";
import ReactStars from 'react-stars';
import { ReviewCardProps } from "./types";
import ReviewModal from "../ReviewModal";
import "./styles.scss";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import { useNavigate } from 'react-router-dom';


const ReviewCard = (props: ReviewCardProps) => {

    const { image, name, rating, comment, postedDate, reviewImages, maskContent } = props;

    const navigate = useNavigate();

    const [showReviewPopup, setShowReviewPopup] = useState<boolean>(false);

    const toggleReviewPopup = () => {
        !maskContent && setShowReviewPopup(!showReviewPopup);
    };

    const goToLogin = () => {
        navigate("/login");
    };

    const preview = reviewImages && reviewImages.map((file: any, idx: number) => (
        <div className="thumb" key={`preview-${idx}`}>
            <div className='thumbInner'>
                <img
                    src={file}
                    className='img'
                    alt=""
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    onClick={toggleReviewPopup}
                />
            </div>
        </div>
    ));

    return (
        <Fragment>
            <div className="review-item-parent">
                <div className={`${maskContent ? "review-item maskContent" : "review-item"}`}>
                    <div className="review-box d-none d-sm-block">
                        <div className="user-image">
                            {image}
                        </div>
                        <div className="review-content">
                            <div className="top-content">
                                <h5 className="user-name">{name}</h5>
                                <div className="posted-time">{postedDate}</div>
                            </div>

                            <div className="rating">
                                <ReactStars
                                    count={5}
                                    size={20}
                                    edit={false}
                                    value={rating}
                                    color2={'#ffd700'} />
                            </div>

                            <div className="review-comment">
                                {comment.length > 340 ? `${comment.substring(0, 340)}... ` : comment}
                                {comment.length > 340 &&
                                    <span
                                        className="read-more"
                                        onClick={toggleReviewPopup}
                                    >Read More</span>}
                            </div>

                            {reviewImages && reviewImages.length > 0 && <div className="review-list-preview">
                                {preview}
                            </div>}
                        </div>
                    </div>

                    <div className="review-box-mobile d-block d-sm-none">
                        <div className="review-top-section">
                            <div className="user-image">
                                {image}
                            </div>
                            <div className="review-header">
                                <div className="top-content">
                                    <h5 className="user-name">{name}</h5>
                                    <div className="posted-time">{postedDate}</div>
                                </div>

                                <div className="rating">
                                    <ReactStars
                                        count={5}
                                        size={20}
                                        edit={false}
                                        value={rating}
                                        color2={'#ffd700'} />
                                </div>
                            </div>
                        </div>
                        <div className="review-content">
                            <div className="review-comment">
                                {comment.length > 340 ? `${comment.substring(0, 340)}... ` : comment}
                                {comment.length > 340 &&
                                    <span
                                        className="read-more"
                                        onClick={toggleReviewPopup}
                                    >Read More</span>}
                            </div>

                            {reviewImages && reviewImages.length > 0 && <div className="review-list-preview">
                                {preview}
                            </div>}
                        </div>
                    </div>


                </div>
                {maskContent && <div className="masked-button-box">
                    <PrimaryButton
                        type="button"
                        buttonText="Login to view more"
                        disabled={false}
                        customClassNames="masked-login-button"
                        buttonClick={() => goToLogin()}
                    />
                </div>}
            </div>

            <ReviewModal
                show={showReviewPopup}
                hideModal={toggleReviewPopup}
                image={image}
                rating={rating}
                name={"Random User"}
                postedDate={postedDate}
                comment={comment}
                reviewImages={reviewImages!}
            />
        </Fragment>
    );
};

export default ReviewCard;