import { Auth } from 'aws-amplify';

export const timestampToDate = (timestamp: number, entity: string) => {

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const date = new Date(timestamp);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;

    const formattedDate = `${day} ${month} ${year}`;
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    if (entity === "date") return formattedDate;
    if (entity === "time") return formattedTime;
    if (entity === "dateTime") return `${formattedDate} ${formattedTime}`;
};

export const updateUserAttributes = async (attributes: any) => {
    const user = await Auth.currentAuthenticatedUser();

    await Auth.updateUserAttributes(user, attributes);
};