import Skeleton from 'react-loading-skeleton';
import "./styles.scss";

const ReviewCardSkeleton = () => {
    return (
        <div className="review-item">
            <div className="review-box d-none d-sm-block">
                <div className="user-image">
                    <Skeleton />
                </div>
                <div className="review-content">
                    <div className="top-content">
                        <h5 className="user-name"><Skeleton /></h5>
                    </div>

                    <div className="rating">
                        <Skeleton />
                    </div>

                    <div className="review-comment">
                        <Skeleton />
                    </div>

                </div>
            </div>

            <div className="review-box-mobile d-block d-sm-none">
                <div className="review-top-section">
                    <div className="user-image">
                        <Skeleton />
                    </div>
                    <div className="review-header">
                        <div className="top-content">
                            <h5 className="user-name"><Skeleton /></h5>
                            <div className="posted-time"><Skeleton /></div>
                        </div>

                        <div className="rating">
                            <Skeleton />
                        </div>
                    </div>
                </div>
                <div className="review-content">
                    <div className="review-comment">
                        <Skeleton />
                    </div>

                    <Skeleton />
                </div>
            </div>
        </div>
    );
};

export default ReviewCardSkeleton;