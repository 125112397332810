export const links = [
    {
        title: "About",
        path: "/about"
    },
    {
        title: "Contact",
        path: "/contact"
    },
    {
        title: "Login",
        path: "/login"
    },
    {
        title: "Register",
        path: "/sign-up"
    },
]