import { Link } from "react-router-dom";
// import ReactStars from 'react-stars';
import { PropertyCardProps } from "./types";
import "./styles.scss";
import Skeleton from 'react-loading-skeleton';

const PropertyCard = (props: PropertyCardProps) => {

    const { item, isLoading } = props;

    return (
        <Link
            className="property-link"
            key={`property-${item?.sef_url}`}
            to={`/property/${item?.sef_url}`}>
            <div className="result-item">
                <div className="detail-section">
                    <div className={isLoading ? "top-section-block" : "top-section"}>
                        <h5 className="property-name">
                            {isLoading ? <Skeleton containerClassName="flex-1" /> : item?.line_1}</h5>
                    </div>

                    <p className="property-location">
                        {isLoading ? <Skeleton /> :
                            `${item?.district}, ${item?.country}`}
                    </p>

                    {/* <div className="bottom-section">
                        <div className="property-star-rating">
                            {isLoading ? <Skeleton width={70} height={20} />
                                : <ReactStars
                                    count={5}
                                    edit={false}
                                    size={20}
                                    value={item?.rating}
                                    color2={'#ffd700'}
                                />}
                        </div>
                         <div className="property-rating">
                            {isLoading ? <Skeleton width={10} /> : item?.rating ? item!.rating : 0}
                        </div> 
                    </div> */}
                </div>
            </div>
        </Link>
    );
};

export default PropertyCard;