import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import TextInput from 'Components/Common/InputFields/TextInput';
import EmailInput from 'Components/Common/InputFields/EmailInput';
import Form from 'react-bootstrap/Form';
import type { RootState } from '../../../App/store';
import { profileSchema } from "Validations/settings.schema";
import { useMutation } from "@tanstack/react-query";
import { updateProfile } from "Api/Profile";
import { toast } from 'react-toastify';
import { updateUserAttributes } from "Helpers";
import { setProfile } from "Pages/Search/slice";
import { useSelector, useDispatch } from 'react-redux';
import PrimaryButton from 'Components/Common/Buttons/PrimaryButton';
import "./styles.scss";

const PersonalInformation = () => {

    const { profile } = useSelector((state: RootState) => state.auth);

    const dispatch = useDispatch();

    const [initialValues, setInitialValues] = useState<{ name: string, email: string }>({ name: '', email: '' });
    const [allowEdit, setAllowEdit] = useState<boolean>(false);

    const { mutate: modifyProfile, isLoading: isUpdating } = useMutation(updateProfile, {
        onSuccess: async () => {
            try {
                await updateUserAttributes({
                    'name': personalInfoForm.values.name
                });

                let profileData = {};

                if (profile) {
                    profileData = { ...profile as {} }
                }

                const updatedProfile = {
                    ...profileData,
                    name: personalInfoForm.values.name
                };

                dispatch(setProfile(updatedProfile));

                toast("Profile updated", {
                    type: toast.TYPE.SUCCESS,
                    containerId: "toast-message",
                    autoClose: 3000
                });
            } catch (error) {
                toast("Failed to update profile", {
                    type: toast.TYPE.ERROR,
                    containerId: "toast-message",
                    autoClose: 3000
                });
            }
        },
        onError: () => {
            toast("Failed to update profile", {
                type: toast.TYPE.ERROR,
                containerId: "toast-message",
                autoClose: 3000
            });
        },
    });

    useEffect(() => {
        if (profile) {
            setInitialValues({
                email: profile["email"],
                name: profile["name"],
            });

            setAllowEdit((profile["provider"] === "email"));
        }
    }, [profile]);

    const personalInfoForm = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: profileSchema,
        onSubmit: values => {
            allowEdit && modifyProfile({ email: values.email, name: values.name });
        },
    });

    useEffect(() => {
        return () => {
            personalInfoForm.resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='personal-section'>
            <div className='section-header'>
                <p className='section-title'>Personal Information</p>
                <p className='section-subtitle'>
                    Update your name and email address in your account settings page
                </p>
            </div>

            <form onSubmit={personalInfoForm.handleSubmit}>
                <div className='content-section'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group className="mb-3">
                                    <TextInput
                                        id="name"
                                        name="name"
                                        placeholder="Enter name"
                                        showLabel={true}
                                        labelText="Name"
                                        disabled={!allowEdit}
                                        errors={personalInfoForm.errors}
                                        touched={personalInfoForm.touched}
                                        testId="code"
                                        value={personalInfoForm.values.name}
                                        handleChange={(e) => personalInfoForm.handleChange(e)}
                                    />
                                </Form.Group>

                            </div>
                            <div className='col-md-6'>
                                <Form.Group className="mb-3">
                                    <EmailInput
                                        id="email"
                                        name="email"
                                        placeholder="Enter email"
                                        showLabel={true}
                                        disabled={true}
                                        labelText="Email"
                                        errors={personalInfoForm.errors}
                                        touched={personalInfoForm.touched}
                                        value={personalInfoForm.values.email}
                                        testId="email"
                                        handleChange={(e) => personalInfoForm.handleChange(e)}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-2'>
                                <PrimaryButton
                                    type="submit"
                                    buttonText="Save"
                                    variant="primary"
                                    isLoading={isUpdating}
                                    disabled={!allowEdit || !personalInfoForm.isValid}
                                    customClassNames="save-button"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    );
};

export default PersonalInformation;